import { HttpErrorResponse } from '@angular/common/http';

export const extractErrorResponse = (errorResponse: HttpErrorResponse, onlyFirstError: boolean) => {
    const errors = [];
    if (errorResponse.error) {
        if (errorResponse.error.data?.content) {
            if (errorResponse.error.data.content instanceof Array) {
                errorResponse.error.data.content.forEach(error => errors.push(error));
            } else {
                errors.push(errorResponse.error.data.content);
            }
        } else {
            if (errorResponse.error.data?.msg) {
                errors.push(errorResponse.error.data.msg);
            } else if (errorResponse.error) {
                for (const property in errorResponse.error) {
                    if (errorResponse.error.hasOwnProperty(property)) {
                        const propertyErrors: Array<string> = errorResponse.error[property];
                        propertyErrors.forEach(error => errors.push(error));
                    }
                }
            }
        }
    }
    return onlyFirstError ? errors[0] : errors;
};
