import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('access_token')
    if (token && !request.url.includes('auth/login')) {
      request = request.clone({
        headers: new HttpHeaders({
          Auth: token,
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          'Api-Client': environment.apiClient,
          'Api-Key': environment.apiKey,
        }),
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError(
        (err) =>
          new Observable<HttpEvent<any>>((observer) => {
            if (err instanceof HttpErrorResponse) {
              const errResp = <HttpErrorResponse>err
              if (errResp.status === 401 || errResp.status === 403) {
                localStorage.removeItem('access_token');
                if (
                  !request.url.includes('password/reset') &&
                  !request.url.includes('/password/create')
                ) {
                  this.router.navigateByUrl('/pages/login');
                }
              }
            }
            observer.error(err);
            observer.complete();
          }),
      ),
    )
  }
}
