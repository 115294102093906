<div class="sidebar" data-color="primary">
    <div class="logo">
        <a class="simple-text logo-mini">
            <div class="logo-img">
                <img src="./assets/img/favicon.png" class="img-transparent" alt="{{user.full_name}}"/>
            </div>
        </a>
        <a [routerLink]="['/home']" class="simple-text logo-normal">
            {{'nameApp' | translate}}
        </a>
        <div class="navbar-minimize">
            <button
                    id="minimizeSidebar"
                    class="btn btn-simple btn-icon btn-neutral btn-round"
                    (click)="minifiedSidebar.emit($event)"
            >
                <i class="now-ui-icons text_align-center visible-on-sidebar-regular"></i>
                <i class="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini"></i>
            </button>
        </div>
    </div>
    <div class="sidebar-wrapper">
        <ul class="nav">
            <li routerLinkActive="active" *ngFor="let menuitem of menuItems">
                <a [routerLink]="[menuitem.path]" *ngIf="!isFather(menuitem)">
                    <i class="{{ menuitem.icontype }}"></i>
                    <p>{{  menuitem.title | translate }}</p>
                </a>
                <a class="isParent" data-toggle="collapse" href="#{{ menuitem.title }}"
                   *ngIf="isFather(menuitem)"
                   (click)="isCollapsing($event, menuitem)"
                   [attr.aria-expanded]="!menuitem.isCollapsed">
                    <i class="{{ menuitem.icontype }}"></i>
                    <p>
                        {{  menuitem.title | translate }}
                        <b class="caret"></b>
                    </p>
                </a>
                <div id="{{ menuitem.title }}" class="collapse" *ngIf="isFather(menuitem)" [ngbCollapse]="menuitem.isCollapsed">
                    <ul class="nav">
                        <li routerLinkActive="active" *ngFor="let childitem of menuitem.children">
                            <a [routerLink]="[childitem.path]">
                                <span class="sidebar-mini-icon mr-0"><i class="{{ childitem.icontype }}"></i></span>
                                <span class="sidebar-normal">{{  childitem.title | translate }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</div>
