import { Entity } from './entity.model';
import { Links } from './links.model';
import { Meta } from './meta.model';

export interface ITermsConditions {
    id?: number;
    data: TermsConditions[];
    links?: Links;
    meta?: Meta;
}
export class TermsConditions extends Entity implements ITermsConditions {
    version: string;
    content: string;
    created_at: Date;
    data: TermsConditions[];
}
