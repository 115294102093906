import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/zone.app.module').then(m => m.ZoneAppModule),
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'pages',
        loadChildren: () => import('./pages/pages/pages.module').then(m => m.PagesModule),
      },
    ],
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
];
