import { Component, OnInit } from '@angular/core';
import 'rxjs/add/operator/filter';
import { Router } from '@angular/router';
import 'rxjs/add/operator/filter';
import { AuthService } from '../../services/auth.service';
import { appConfigs } from '../../constants/initialize';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
})
export class AdminLayoutComponent {
    public classToggle = '';
    public viewInMobile = false;
    public classSidebarMini = '';

    constructor(public router: Router, private authService: AuthService) {
    }

    onToggle() {
        if (this.classToggle === '') {
            this.viewInMobile = true;
            this.classToggle =  appConfigs.classOpenNav;
        } else {
            this.viewInMobile = false;
            this.classToggle = '';
        }
    }
    onMinifiedSidebar() {
        if ( this.viewInMobile) {
            this.viewInMobile = false;
            this.classToggle =  '';
        } else {
                this.classSidebarMini = this.classSidebarMini === '' ? appConfigs.classSidebarMini : '';
        }
    }

    onLogout() {
        this.authService.logout().subscribe(
            (res: Response) => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('user');
                this.router.navigateByUrl('/pages/login');
            }
        );
    }
}
