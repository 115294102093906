import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { RouteInfo } from '../../models/sidebar';
import { appConfigs } from '../../constants/initialize';
import {User} from "../../models/users.model";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
    @Output() toggle = new EventEmitter();
    @Output() logout = new EventEmitter();
    public isCollapsed = false;
    private listTitles: RouteInfo[];
    public location: Location;
    private sidebarVisible: boolean;
    public navbarClassList = appConfigs.navBarTransparent;
    user: User;

    constructor(
        location: Location,
    ) {
        this.location = location;
        this.sidebarVisible = false;
    }

    ngOnInit() {
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'));
        }

        this.listTitles = this.user.menu.filter(listTitle => listTitle);
    }

    getTitle() {
        let title = this.location.prepareExternalUrl(this.location.path());
        if (title.charAt(0) === '#') {
            title = title.slice(1);
        }
        return (this.listTitles.find((item) => {
            if (item.type === appConfigs.evaluatorLink && item.path === title) {
                return item.title;
            }
        }))?.title;
    }

    collapse() {
        this.isCollapsed = !this.isCollapsed;
        if (this.isCollapsed) {
            this.navbarClassList = appConfigs.bgColor;
        } else {
            this.navbarClassList = appConfigs.navBarTransparent;
        }

    }
}
