export const appServiceURL = {
    listsSubscription: '/select/subscriptions',
    listsProviders: '/select/providers',
    listsCategories: '/select/categories/children',
    listsCatalogs: '/select/catalogs',
    listsRoles: '/select/roles',
    listsTagServices: '/list/tagServices',
    listsTagGenerals: '/list/tagGeneral',
    listsSides: '/sectors',
    listsTypesworkflow: '/typeworkflows',
    moduleAdmins: '/users',
    moduleUsers: '/users',
    moduleCatalogs: '/catalogs',
    moduleCategories: '/categories',
    moduleNotaries: '/notaries',
    moduleLawyers: '/lawyers',
    moduleWorkflows: '/workflows',
    moduleTermsConditions: '/termConditions',
    termsConditions: '/list/term-conditions',
    moduleSubCategories: '/subcategories',
    listsCommentsCatalogs: '/list/catalogs/{id}/comments',
    moduleReviews: '/comments',
    moduleEvents: '/events',
    modulePromotions: '/promotions',
    loadDocuments: '/documents',
    listDocuments: '/documents/listByWorkflow/',
    downloadDocuments: '/documents/download/',
    deleteDocument: '/documents/delete',
};
