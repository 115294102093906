import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ComponentsModule} from './components/components.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';

import {AppComponent} from './app.component';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {RequestInterceptor} from './interceptor/request.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {SharedModule} from './shared.module';

import {AppRoutes} from './app.routing';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';

@NgModule({
    declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent, PrivacyPolicyComponent],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forRoot(AppRoutes, {
            useHash: true,
        }),
        NgbModule,
        ToastrModule.forRoot(), // ToastrModule added
        ComponentsModule,
        SharedModule,
        NgxUiLoaderModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
