import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    authUrl = environment.urlServer + '/auth/login';
    options: any;

    constructor(private http: HttpClient) {
        this.options = {
            headers: new HttpHeaders({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Api-Client': environment.apiClient,
                'Api-Key': environment.apiKey,
            }),
        };
    }

    login(email: string, password: string) {
        return this.http.post(
            this.authUrl,
            {
                email: email,
                password: password,
            },
            this.options
        );
    }

    logout() {
        return this.http.post(
            environment.urlServer + '/auth/logout',
            this.options
        );
    }

    getUnLockCode(email: string): Observable<any> {
        const url = environment.urlServer + '/password/create';
        return this.http.post(
            url,
            {
                email: email,
            },
            this.options
        );
    }

    checkActivationCode(email: string, code: string): Observable<any> {
        const url = environment.urlServer + '/auth/activate';
        return this.http.post(
            url,
            {
                email: email,
                code_activate: code,
            },
            this.options
        );
    }

    getUnLockCodePartII(
        email: string,
        code: string,
        password: string,
        passwordConfirmation: string
    ): Observable<any> {
        const url = environment.urlServer + '/password/reset';
        return this.http.post(
            url,
            {
                email: email,
                token: code,
                password: password,
                password_confirmation: passwordConfirmation,
            },
            this.options
        );
    }
}
