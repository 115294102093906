<nav class="navbar navbar-expand-lg navbar-absolute {{navbarClassList}}">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <div class="navbar-toggle">
                <button type="button" class="navbar-toggler" (click)="toggle.emit($event)">
                    <span class="navbar-toggler-bar bar1"></span>
                    <span class="navbar-toggler-bar bar2"></span>
                    <span class="navbar-toggler-bar bar3"></span>
                </button>
            </div>
            <a class="navbar-brand" href="#">{{ getTitle() |translate }}</a>
        </div>
        <button class="navbar-toggler" type="button" (click)="collapse()"
                [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="!isCollapsed">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" (click)="logout.emit($event)">
                        <i class="fas fa-sign-out-alt fa-2x"></i>
                        <p><span class="d-lg-none d-md-block">{{'logout'| translate}}</span></p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
