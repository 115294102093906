import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Entity, IEntity } from '../models/entity.model';
import { HelperService } from '../utils/helpers';
import { General } from '../models/general.model';
import { appConfigs } from '../constants/initialize';
import { BodyParam } from '../models/bodyparam.model';

type EntityResponseType = HttpResponse<IEntity|General>;

export class AbstractService<T extends Entity> {
    constructor(protected http: HttpClient, private resourceUrl: string) {}

    protected getResourceUrl() {
        return this.resourceUrl;
    }

    create(entity: IEntity): Observable<EntityResponseType> {
        return this.http.post<IEntity>(this.resourceUrl, entity, { observe: 'response', headers: HelperService.options() });
    }

    update(id: number, entity: IEntity): Observable<EntityResponseType> {
        return this.http.put<IEntity>(`${this.resourceUrl}/${id}`, entity, { observe: 'response', headers: HelperService.options() });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<IEntity>(`${this.resourceUrl}/${id}`, { observe: 'response', headers: HelperService.options() });
    }

    query(queryParams: HttpParams): Observable<EntityResponseType> {
        return this.http.get<IEntity>(this.resourceUrl, { params: queryParams, observe: 'response', headers: HelperService.options() });
    }

    delete(id: number): Observable<EntityResponseType> {
        return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response', headers: HelperService.options() });
    }

    createOrUpdate(action: string, entity: IEntity|BodyParam): Observable<EntityResponseType> {
        if (action === appConfigs.actionUpdate) {
            return this.update(entity.id, entity);
        }
        return this.create(entity);
    }
}
