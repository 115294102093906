import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DxVectorMapModule, DxPieChartModule } from 'devextreme-angular';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';

import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SharedModule } from '../shared.module';
import { NavbarComponent } from './navbar/navbar.component';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        FormsModule,
        JwBootstrapSwitchNg2Module,
        NgbModule,
        DxVectorMapModule,
        DxPieChartModule,
        SharedModule,
    ],
    declarations: [
        FooterComponent,
        SidebarComponent,
        NavbarComponent,

    ],
    exports: [
        FooterComponent,
        SidebarComponent,
        NavbarComponent,
    ],
})
export class ComponentsModule {
}
