import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { environment } from '../../environments/environment';
import { appServiceURL } from '../constants/routes';
import { ICategory } from '../models/categories.model';
import { ITermsConditions, TermsConditions } from '../models/terms-conditions.model';
import { Observable } from 'rxjs';
import { HelperService } from '../utils/helpers';
import { IPolicyPrivacy } from '../models/policy-privacy.model';
@Injectable({
    providedIn: 'root',
})
export class TermsConditionsService extends AbstractService<ITermsConditions> {
    constructor(httpClient: HttpClient) {
        super(httpClient, `${environment.urlServer}${appServiceURL.moduleTermsConditions}`);
    }
    lastTermsConditions(): Observable<HttpResponse<IPolicyPrivacy>> {
        return this.http.get<IPolicyPrivacy>(`${environment.urlServer}${appServiceURL.termsConditions}`,
            { observe: 'response', headers: HelperService.options() });
    }
}
