import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { User } from '../../models/users.model';
import { RouteInfo } from '../../models/sidebar';
import { appConfigs } from '../../constants/initialize';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
    @Output() logout = new EventEmitter();
    @Output() minifiedSidebar = new EventEmitter();
    menuItems: RouteInfo[];
    isCollapsed = true;
    user: User;
    ngOnInit() {
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'));
        }
        this.menuItems = this.user.menu;
    }

    optCollapsing(event, menuitem) {
        event.preventDefault();
        event.stopPropagation();
        if (menuitem !== undefined && menuitem.isCollapsing === undefined) {
            menuitem.isCollapsing = true;
        }
    }

    isCollapsing(event, menuitem) {
        event.preventDefault();
        event.stopPropagation();
        return menuitem.isCollapsed = !menuitem.isCollapsed;
    }

    isFather(menuitem: RouteInfo) {
        return menuitem.type === appConfigs.typeFather;
    }
}
