import {Component, OnInit} from '@angular/core';
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from '@angular/common';

import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-auth-layout',
    templateUrl: './auth-layout.component.html',
    styleUrls: ['./auth-layout.component.css', '../../../styles.css'],
})
export class AuthLayoutComponent implements OnInit {
    public location: Location;
    public isCollapsed = false;

    constructor(location: Location
        , private router: Router
        , private translateService: TranslateService) {
        this.location = location;
    }

    ngOnInit() {
        this.router.events.subscribe((event) => {
            this.isCollapsed = false;
        });
    }

    getTitle() {
        return this.translateService.instant('nameApp');
    }

}
