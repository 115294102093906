import { HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { helpers } from 'chart.js';
import { appConfigs } from '../constants/initialize';

export class HelperService {

    constructor() {
    }

    static options() {
        return new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Api-Client': environment.apiClient,
            'Api-Key': environment.apiKey,
            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
        });
    }
    static optionsFormData() {
        return new HttpHeaders({
            Accept: 'multipart/form-data',
            'Content-Type': 'multipart/form-data',
            'Api-Client': environment.apiClient,
            'Api-Key': environment.apiKey,
            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
        });
    }

    static formatDate(date) {
        if (date !== undefined) {
            return new Date(date).toJSON().split('T')[0];
        }
        return appConfigs.empty;
    }

    static getFormData(object) {
        const formData = new FormData();
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }
    static cleanFormatSocialLink(valueToClean: string) {
        return valueToClean.replace(appConfigs.phoneFormat, appConfigs.empty)
            .replace(appConfigs.whatsappFormatUrl, appConfigs.empty)
            .replace(appConfigs.whatsappFormatText, appConfigs.empty)
            .replace(appConfigs.facebookFormatUrl, appConfigs.empty)
            .replace(appConfigs.instagramFormatUrl, appConfigs.empty);
    }
}
