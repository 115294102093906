import { Entity } from './entity.model';
import { Links } from './links.model';
import { Meta } from './meta.model';

export interface IPolicyPrivacy {
    id?: number;
    data: PolicyPrivacy;
}
export class PolicyPrivacy extends Entity implements IPolicyPrivacy {
    version: string;
    content: string;
    created_at: Date;
    data: PolicyPrivacy;
}
