export const appConfigs = {
    page_init: 1,
    per_page : 15,
    column_default : 'id',
    orientationAsc : 'asc',
    orientationDesc : 'desc',
    actionAdd: 'create',
    actionUpdate: 'update',
    roleClient: 'user',
    roleLawyer: 'lawyer',
    roleProvider: 'provider',
    roleAdmin: 'admin',
    defaultPage: 'dashboard',
    evaluatorLink: 'link',
    evaluatorSubItem: 'sub',
    classOpenNav: 'nav-open',
    classSidebarMini: 'sidebar-mini',
    bgColor: 'bg-white',
    navBarTransparent: 'navbar-transparent',
    titlePageClient: 'listClients',
    titlePageLawyer: 'listLawyers',
    titlePageProvider: 'listProviders',
    titlePageAdmin: 'listAdmins',
    titlePageNotary: 'listNotaries',
    titlePageWorkflow: 'listWorkflows',
    titleModalClient: 'editCreateClients',
    titleModalLawyer: 'editCreateLawyers',
    titleModalProvider: 'editCreateProviders',
    titleModalAdmin: 'editCreateAdmins',
    titleModalNotary: 'editCreateNotaries',
    titleModalWorkflow: 'editCreateWorkflows',
    titleModalRating: 'editCreateRating',
    typeFather: 'father',
    typeLink: 'link',
    minHour: '00:00',
    image: 'image',
    video: 'video',
    maxPhones: 4,
    maxImages: 5,
    maxVideos: 1,
    maxItemsMedia: 6,
    maxItemsCoverBackground: 2,
    imagePlaceHolder: '/assets/img/placeholder.png',
    empty: '',
    newIndex: -1,
    maxSizeFileImage: 3072,
    maxSizeFileVideo: 4096,
    maxSizeFileImageMessage: 'maxSizeFileImageMessage',
    maxSizeFileVideoMessage: 'maxSizeFileVideoMessage',
    toolbarEditorDefault: [
        ['bold', 'italic'],
        ['underline'],
        [{ heading: ['h1', 'h2', 'h3'] }],
        ['link'],
        ['text_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ],
    mediumSize: 'md',
    largeSize: 'xl',
    idParamsGeneric: '{id}',
    maxStars: 5,
    starFull: 'fas fa-star',
    starEmpty: 'fas fa-star text-gray',
    userCodeBlocked: 423,
    dateFormatInput: 'yyyy-MM-ddTHH:mm',
    dateFormatShortInput: 'yyyy-MM-dd',
    coverPosition: 0,
    backgroundPosition: 1,
    indicatorSize: 1024,
    countryPhone: 'EC',
    phoneFormat: 'tel://',
    whatsappFormatUrl: 'https://wa.me/',
    whatsappFormatText: '/?text=Hola estoy interesado',
    instagramFormatUrl: 'https://instagram.com/',
    facebookFormatUrl: 'https://facebook.com/',
};

